import { render, staticRenderFns } from "./allCategoriesPage.vue?vue&type=template&id=70433bec&scoped=true&"
import script from "./allCategoriesPage.vue?vue&type=script&lang=js&"
export * from "./allCategoriesPage.vue?vue&type=script&lang=js&"
import style0 from "./allCategoriesPage.vue?vue&type=style&index=0&id=70433bec&prod&lang=scss&scoped=true&"
import style1 from "./allCategoriesPage.vue?vue&type=style&index=1&id=70433bec&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70433bec",
  null
  
)

export default component.exports